/* Global imports */
import React from "react";
import PropTypes from "prop-types";
import { combine } from "../helper/classNames";

/* Local imports */
const CarouselControl = ({ next, previous, blogSeries, ...rest }) => {
  if (rest.carouselState.totalItems <= 1) {
    return null;
  }
  return (
    <div
      className={combine("frient-carousel-control", blogSeries && "centered")}
    >
      <div
        className={
          "react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        }
        onClick={previous}
        role="button"
        onKeyDown={previous}
        tabIndex={0}
        aria-label="previous"
      ></div>
      <div
        className={
          "react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        }
        onClick={next}
        role="button"
        onKeyDown={next}
        tabIndex={0}
        aria-label="next"
      ></div>
    </div>
  );
};

CarouselControl.propTypes = {
  blogSeries: PropTypes.bool,
  next: PropTypes.func,
  previous: PropTypes.func,
};
CarouselControl.defaultProps = {
  blogSeries: false,
};

export default CarouselControl;
